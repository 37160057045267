import { FC, MouseEvent, useMemo } from 'react';
import ExternalLinkIcon from '../../../shared/icon/ExternalLink';
import MiddleTruncate from '../../../shared/MiddleTruncate';
import Tooltip from '../../../shared/Tooltip';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';

const LinkPreview: FC<ActionPreviewBaseProps<string>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewDescription, previewQuestion } = data;

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  const answer = useMemo(() => {
    if (!response) {
      return '-';
    }

    const openFile = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const url = response.match(/^https?:\/\//i) ? response : `http://${response}`;
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = url;
      link.click();
    };

    return (
      <Tooltip text={response}>
        {(tooltip) => (
          <div {...tooltip} className="cursor-pointer" onClick={openFile}>
            <div className="min-w-0 max-w-full underline">
              <MiddleTruncate>
                <span className="ellipseMe">{response}</span>
                {!answerOnly && <ExternalLinkIcon className="ml-2 h-6 w-6 flex-shrink-0" />}
              </MiddleTruncate>
            </div>
          </div>
        )}
      </Tooltip>
    );
  }, [answerOnly, response]);

  if (answerOnly) {
    return <>{answer}</>;
  }

  return <ActionPreview data-cy="link-preview" question={title} description={previewDescription} answer={answer} />;
};
export default LinkPreview;
